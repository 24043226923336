// resources
import { DefaultTheme } from 'styled-components';

import LetterGothicStdBoldSlanted from './fonts/LetterGothicStd-BoldSlanted.woff';
import LetterGothicStdBoldSlanted2 from './fonts/LetterGothicStd-BoldSlanted.woff2';
import LetterGothicStdSlanted from './fonts/LetterGothicStd-Slanted.woff';
import LetterGothicStdSlanted2 from './fonts/LetterGothicStd-Slanted.woff2';
import LetterGothicStd from './fonts/LetterGothicStd.woff';
import LetterGothicStd2 from './fonts/LetterGothicStd.woff2';
import MyriadProBold from './fonts/MyriadPro-Bold.woff';
import MyriadProBoldItalic from './fonts/MyriadPro-BoldItalic.woff';
import MyriadProLight from './fonts/MyriadPro-Light.woff';
import MyriadProRegular from './fonts/MyriadPro-Regular.woff';
import HGCLogo from './img/logo.png';

export const HGC2020Theme: DefaultTheme = {
  size: {
    font: '12pt',
  },
  color: {
    title: '#000000',
    text: '#000000',
    link: '#0011ff',
    background: '#ffffff',
  },
  fonts: {
    title: {
      family: ['Letter Gothic Std', 'sans-serif'],
      style: 'normal',
      weight: 'normal',
      size: '1.2rem',
    },
    text: {
      family: ['Myriad Pro', 'sans-serif'],
      style: 'normal',
      weight: 'normal',
      size: '1.0rem',
    },
    link: {
      family: ['Myriad Pro', 'sans-serif'],
      style: 'normal',
      weight: 'normal',
      size: '1.0rem',
    },
  },
  fontResources: [
    {
      family: 'Letter Gothic Std',
      style: 'normal',
      weight: 'normal',
      imports: [
        { import: LetterGothicStd, format: 'woff' },
        { import: LetterGothicStd2, format: 'woff2' },
      ],
    },
    {
      family: 'Letter Gothic Std',
      style: 'italic',
      weight: 'normal',
      imports: [
        { import: LetterGothicStdSlanted, format: 'woff' },
        { import: LetterGothicStdSlanted2, format: 'woff2' },
      ],
    },
    {
      family: 'Letter Gothic Std',
      style: 'italic',
      weight: 'bold',
      imports: [
        { import: LetterGothicStdBoldSlanted, format: 'woff' },
        { import: LetterGothicStdBoldSlanted2, format: 'woff2' },
      ],
    },
    {
      family: 'Myriad Pro',
      style: 'normal',
      weight: 300,
      imports: [{ import: MyriadProLight, format: 'woff' }],
    },
    {
      family: 'Myriad Pro',
      style: 'normal',
      weight: 'normal',
      imports: [{ import: MyriadProRegular, format: 'woff' }],
    },
    {
      family: 'Myriad Pro',
      style: 'normal',
      weight: 'bold',
      imports: [{ import: MyriadProBold, format: 'woff' }],
    },
    {
      family: 'Myriad Pro',
      style: 'italic',
      weight: 'bold',
      imports: [{ import: MyriadProBoldItalic, format: 'woff' }],
    },
  ],
  logo: HGCLogo,
};
