import { FC, PropsWithChildren } from 'react';

import { StyledThemeProvider } from '../theming/context';
import { AuthProvider } from './AuthContext';

export const Providers: FC<PropsWithChildren<{}>> = ({
  children,
}: PropsWithChildren<{}>): JSX.Element => (
  <AuthProvider>
    <StyledThemeProvider>{children}</StyledThemeProvider>
  </AuthProvider>
);
