import { FC, ReactNode, useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';

import { HGC2020Theme } from './themes/hgc2021';

export const StyledThemeProvider: FC<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }): JSX.Element => {
  const [theme] = useState<DefaultTheme>(HGC2020Theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
