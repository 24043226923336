import { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { AuthLayout } from '../components/Auth/AuthLayout';

export const AuthPage: FC = (): JSX.Element => {
  const { path, url } = useRouteMatch();

  return (
    <AuthLayout>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${url}/login`} />
        </Route>
        <Route path={`${path}/login`}>login</Route>
        <Route path={`${path}/sign-up`}>sign up</Route>
      </Switch>
    </AuthLayout>
  );
};
