import { FC, lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { basePath, isProduction } from './config';
import { AdminPage } from './pages/Admin.page';
import { AuthPage } from './pages/Auth.page';
import { ProfilePage } from './pages/Profile.page';
import { AuthenticatedRoute } from './services/AuthenticatedRoute';
import { Providers } from './services/Providers';
import { GlobalStyle } from './theming/global';

const DebugPane = lazy(() => import('./components/DebugPane'));

export const App: FC = (): JSX.Element => (
  <Providers>
    <GlobalStyle />

    <BrowserRouter basename={basePath}>
      {!isProduction && (
        <Suspense fallback={<div>Loading Debug Pane...</div>}>
          <DebugPane />
        </Suspense>
      )}

      <Switch>
        <Route exact path="/">
          <Redirect to="/auth" />
        </Route>

        <Route path="/auth" component={AuthPage} />

        <AuthenticatedRoute
          hasNoRoleOf={['admin']}
          path="/profile"
          component={ProfilePage}
        />
        <AuthenticatedRoute
          hasAnyRoleOf={['admin']}
          path="/admin"
          component={AdminPage}
        />

        <Route>404</Route>
      </Switch>
    </BrowserRouter>
  </Providers>
);
