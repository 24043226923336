import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuth } from './AuthContext';

export type AuthenticatedRouteProps = RouteProps & {
  hasAnyRoleOf?: string[];
  hasNoRoleOf?: string[];
};

export const AuthenticatedRoute: FC<AuthenticatedRouteProps> = ({
  hasAnyRoleOf,
  hasNoRoleOf,
  children,
  ...rest
}: AuthenticatedRouteProps): JSX.Element => {
  const [auth] = useAuth();

  const isAuthorized =
    auth !== null &&
    (hasAnyRoleOf === undefined || hasAnyRoleOf.includes(auth.role)) &&
    (hasNoRoleOf === undefined || !hasNoRoleOf.includes(auth.role));

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...rest}>
      {isAuthorized ? children : <Redirect to="/auth/login" />}
    </Route>
  );
};
