import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

// context data type
export type AuthContextSession = { role: string } | null;

// wrapping type with context value and setter
export type AuthContextContainer = [
  AuthContextSession,
  Dispatch<SetStateAction<AuthContextSession>>
];

// auth context object
export const AuthContext = createContext<AuthContextContainer>([
  null,
  // eslint-disable-next-line no-console
  () => console.warn('no auth context state provider initialized'),
]);

export type AuthProviderProps = PropsWithChildren<{}>;

// provider component
export const AuthProvider: FC<AuthProviderProps> = ({
  children,
}: AuthProviderProps): JSX.Element => {
  const initialSession: AuthContextSession = null;

  // load session once, on load
  useEffect(() => {
    // TODO: load session from localStorage or something similar
  }, []);

  const [auth, setAuth] = useState<AuthContextSession>(initialSession);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

// custom consumer hook
export const useAuth = () => useContext(AuthContext);
