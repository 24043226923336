import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
${({ theme }) =>
  theme.fontResources.map(
    // load all fonts
    (f) => `
    @font-face {
      font-family: "${f.family}";
      src: local('${f.family}'), ${f.imports
      .map((r) => `url(${r.import}) format('${r.format}')`)
      .join(',')};
      font-weight: ${f.weight};
      font-style: ${f.style};
    }`
  )}

html {
  font-size: ${({ theme }) => theme.size.font};
  font-family: ${({ theme }) =>
    theme.fonts.text.family.map((f) => `"${f}"`).join(', ')};
}

/*
* CSS reset
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

[hidden] {
  display: none;
}

:focus {
  outline: 0;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}`;
